<template>
  <div class="d-flex flex-column align-center error-page">
    <div class="error-code text-center mb-15">
      <h1 class="error-code__title">404</h1>
      <h2>Página no encontrada</h2>
      <v-btn color="primary" block
             small
             class="mt-2 elevation-0 text-none"
             @click="goToLogin">
        Ir al inicio
      </v-btn>
    </div>
    <div class="error-page__banner"></div>
  </div>
</template>
<script>
export default {
  methods: {
    goToLogin() {
      this.$router.push({name: 'login'})
    }
  }
}
</script>
